import React, { useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { parse } from 'query-string'
import Cookie from 'js-cookie'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Skeleton from '@material-ui/lab/Skeleton'

import LayoutContent from '../components/layout/layoutContent'
import { getLocaleFromPath } from '../utilities/locale'
import { getUToken } from '../services/xboxLive'
import useAuthentication from '../hooks/useAuthentication'

const LOGIN_FEATURE_ENABLED = !!process.env.GATSBY_FEATURE_LOGIN_ON && JSON.parse(process.env.GATSBY_FEATURE_LOGIN_ON.toLowerCase()) === true

const ERROR_REDIRECT = '/error'

const CARD_WIDTH = '100vw'
const CARD_HEIGHT = '620px'
const CARD_WIDTH_DESKTOP = '450px'
const CARD_HEIGHT_DESKTOP = '800px'

const useClasses = makeStyles(theme => ({
    skeletonHeading: { marginLeft: 'auto', marginRight: 'auto' },
    skeletonBoxContainer: {
        justifyContent: 'center',
        display: 'grid',
        gridColumnGap: '1em',
        gridTemplateColumns: `repeat(auto-fit, ${CARD_WIDTH})`,
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: `repeat(auto-fit, ${CARD_WIDTH_DESKTOP})`
        }
    }
}))

const AuthPage = props => {
    const classes = useClasses()
    const { location, navigate } = props
    const parsed = parse(location.hash)
    const logout = useAuthentication().pop()
    const locale = parsed.state ? getLocaleFromPath(parsed.state) || 'en-us' : 'en-us'
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

    useEffect(() => {
        if (!LOGIN_FEATURE_ENABLED || !parsed.access_token) {
            logout()
            navigate(`/${locale}`)
        } else {
            getUToken(parsed.access_token)
                .then(uData => {

                    Cookie.set('auth', uData.Token, { secure: true, sameSite: 'strict', expires: new Date(uData.NotAfter) })

                    if (parsed.state) {
                        navigate(parsed.state.replace(process.env.GATSBY_SITE_URL, ''), { replace: true })
                    } else {
                        navigate(`/${locale}`)
                    }
                })
                .catch(error => {
                    console.error(error)
                    navigate(`/${locale}${ERROR_REDIRECT}`, { state: { errorType: 'auth', errorCode: Number(error) } })
                })
        }
    }, [locale, logout, navigate, parsed.access_token, parsed.state])

    return (
        <LayoutContent {...props} hasVerticalGutters>
            <Skeleton height='2em' width='50%' classes={{ root: classes.skeletonHeading }} />
            <div className={classes.skeletonBoxContainer}>
                <Skeleton height={isMobile ? CARD_HEIGHT : CARD_HEIGHT_DESKTOP} />
                <Skeleton height={isMobile ? CARD_HEIGHT : CARD_HEIGHT_DESKTOP} />
                <Skeleton height={isMobile ? CARD_HEIGHT : CARD_HEIGHT_DESKTOP} />
            </div>
        </LayoutContent>
    )
}

export default AuthPage
