import { useEffect } from 'react'

import useCookie from './useCookie'

const UTOKEN_COOKIE_NAME = 'auth'

const useAuthentication = () => {
    const [uToken, setUToken] = useCookie(UTOKEN_COOKIE_NAME)
    const isAuthenticated = Boolean(uToken)
    const logout = () => setUToken(null)

    useEffect(() => {
        if (!isAuthenticated) {
            setUToken(null)
        }
    }, [isAuthenticated, setUToken])

    return [isAuthenticated, logout]
}

export default useAuthentication
